



























































import useMisc from "@/use/misc";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const { formatCurrency } = useMisc({ root });

    const model = reactive({
      initialAmount: 0,
      budgetCosts: [] as any,
    });

    const state = reactive({
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      currency: "PLN",
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    const addCost = () => {
      model.budgetCosts.push({
        name: "",
        value: 0,
      });
    };

    const removeItem = (index: number) => {
      model.budgetCosts.splice(index, 1);
    };

    const leftAmount = computed(() => {
      const allCosts = model.budgetCosts.reduce(
        (accum: any, item: any) => accum + parseFloat(item.value),
        0
      );
      return model.initialAmount - allCosts;
    });

    const fetchBudget = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/budget`)
        .then(({ data }) => {
          model.initialAmount = data.initialAmount;
          model.budgetCosts = data.costs.map((c: any) => ({
            name: c.name,
            value: c.value,
          }));
        })
        .catch((e) => console.log(e));
    };

    onMounted(fetchBudget);

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        initialAmount: model.initialAmount,
        leftAmount: leftAmount.value,
        costs: model.budgetCosts,
      };

      axiosInstance
        .put(`event/${root.$route.params.id}/budget`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("event.panel.modules.budgetModule.view.success"),
          });
        })
        .catch((error) => {
          state.error = error.response?.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    return {
      formatCurrency,

      model,
      state,

      rules,
      addCost,
      removeItem,
      onSubmit,

      leftAmount,
    };
  },
});
